import { ColumnsType } from "antd/lib/table/interface";
import { IncidentClientSide } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import Highlight from "@/components/Highlight";

export const useIncidentColumns = () => {
  const t = useTranslations();

  return [
    {
      title: t("incidents.table.name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ alarm_rule_id, highlight, created_at }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          `${alarm_rule_id} ${dayjs(created_at).format("DD/MM YYYY HH:mm")}`
        ),
    },
    {
      title: t("incidents.table.active"),
      dataIndex: "active",
      key: "active",
      sorter: true,
      width: 100,
      align: "center",
      render: (active) =>
        active ? (
          <div className="w-3 h-3 bg-red-500 rounded-full m-auto" />
        ) : undefined,
    },
    {
      title: t("incidents.table.last-comment"),
      dataIndex: "last_comment",
      key: "last-comment",
      sorter: true,
    },
    {
      title: t("incidents.table.timeserie"),
      dataIndex: "timeseries",
      key: "timeseries",
      sorter: true,
    },
    {
      title: t("incidents.table.severity"),
      dataIndex: "severity",
      key: "severity",
      sorter: true,
    },
    {
      title: t("incidents.table.responsible"),
      key: "responsible",
      sorter: true,
      render: ({ assigned, acknowledged }) => {
        if (assigned?.user) {
          return <div className="font-bold text-blue-500">{assigned.user}</div>;
        }
        return acknowledged?.user || "";
      },
    },
    {
      title: t("incidents.table.reported-at"),
      dataIndex: "start",
      key: "reported-at",
      sorter: true,
      render: (start) => dayjs(start).format("DD/MM YYYY HH:mm"),
    },
    {
      title: t("incidents.table.resolved-at"),
      dataIndex: "end",
      key: "resolved-at",
      sorter: true,
      render: (start) => dayjs(start).format("DD/MM YYYY HH:mm"),
    },
    {
      title: t("incidents.table.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => <div className="capitalize">{status}</div>,
    },
  ] as ColumnsType<IncidentClientSide>;
};
