import { Select, Spin } from "antd";
import { useSubBuildingsForCurrentType } from "@/pages/alarms/details/hooks/useSubBuildingsForCurrentType";
import { TimeseriesThresholdFormFields } from "../TimeseriesThreshold";
import { FormContextItem } from "../../FormContext";
import { tKey } from "./utils";

export function SelectSubBuilding() {
  const { subBuildings, isLoading } = useSubBuildingsForCurrentType();

  if (isLoading) {
    return <Spin />;
  }

  return (
    <FormContextItem
      id={TimeseriesThresholdFormFields.SubBuilding}
      labelKey={tKey(`timeseries-threshold.select-sub-building`)}
      antdInput
      inputProps={{
        showSearch: true,
        disabled: subBuildings.length === 0,
      }}
    >
      <Select>
        {subBuildings.map((subBuilding) => (
          <Select.Option
            value={subBuilding.id}
            key={subBuilding.id}
            disabled={!subBuilding.available}
          >
            {subBuilding.name}
          </Select.Option>
        ))}
      </Select>
    </FormContextItem>
  );
}
