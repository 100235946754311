import { PropsWithChildren } from "react";
import { TranslationFunction } from "@properate/translations/src";

export type FormEntryValidator<T> = (
  value: T | unknown,
  entries: FormContextEntries,
) => {
  valid: boolean;
  message?: string;
};

export type FormContextEntry<T = unknown> = {
  defaultValue: T;
  value?: T;
  getValidator?: (t: TranslationFunction) => FormEntryValidator<T>;
  skipWhenValidatingAll?: boolean;
  defaultErrorMessage?: string;
  errorMessage?: string;
  hasError?: boolean;
};

export type SubmitItem<T = unknown> = Pick<
  FormContextEntry<T>,
  "value" | "hasError" | "errorMessage"
>;

export type FormContextEntries<T = unknown> = Record<
  string,
  FormContextEntry<T>
>;

export type SubmitValue = Record<keyof FormContextEntries, SubmitItem>;
export type SubmitFunction = (values: SubmitValue) => unknown;

export type CreateAlarmRuleCommonProps = { entries: SubmitValue };

export type FormContextType<T = unknown> = {
  entries: FormContextEntries<T>;
  options: FormContextOptions;
  t: TranslationFunction;
  onSubmit: SubmitFunction;
};

export type FormContextOptions = {
  useUrlSearchParams?: boolean;
};

export enum FormContextActionType {
  change,
  validate,
  validateField,
  validateFields,
  clear,
  clearFields,
  addFieldsIfMissing,
  submit,
}

export type ResultCallbackFunctionSingleEntry = ({
  entry,
  hasError,
}: {
  entry: FormContextEntry;
  hasError: boolean;
}) => void;

export type ResultCallbackFunctionParams = {
  entries: FormContextEntries;
  hasError: boolean;
  errorIds: string[];
};
export type ResultCallbackFunction = ({
  entries,
  hasError,
  errorIds,
}: ResultCallbackFunctionParams) => void;

export type ValidateFieldsFormContextAction = {
  type: FormContextActionType.validateFields;
  ids: string[];
  resultCallback?: ResultCallbackFunction;
  ignoreSkipWhenValidatingAll?: boolean;
};

export type ValidateFieldFormContextAction = {
  type: FormContextActionType.validateField;
  id: string;
  resultCallback?: ResultCallbackFunctionSingleEntry;
  ignoreSkipWhenValidatingAll?: boolean;
};

export type ValidateAllFormContextAction = {
  type: FormContextActionType.validate | FormContextActionType.submit;
  resultCallback?: ResultCallbackFunction;
  ignoreSkipWhenValidatingAll?: boolean;
};

export type ValidationFormContextAction =
  | ValidateAllFormContextAction
  | ValidateFieldFormContextAction
  | ValidateFieldsFormContextAction;

export type FormContextAction =
  | {
      type: FormContextActionType.change;
      id: string;
      value: unknown;
    }
  | {
      type: FormContextActionType.clear;
    }
  | {
      type: FormContextActionType.addFieldsIfMissing;
      entries: FormContextEntries;
    }
  | {
      type: FormContextActionType.clearFields;
      ids: string[];
    }
  | ValidationFormContextAction;

export type FormContextProviderEntries = Record<
  string,
  Pick<
    FormContextEntry<unknown>,
    "defaultValue" | "defaultErrorMessage" | "getValidator"
  >
>;

export type FormContextProviderProps = PropsWithChildren & {
  onSubmit: SubmitFunction;
  entries: FormContextProviderEntries;
  className?: string;
  options?: FormContextOptions;
};

export type FormContextFormProps = Pick<
  FormContextProviderProps,
  "children" | "className"
>;
