import { PageHeader } from "@ant-design/pro-layout";
import { Fragment } from "react";
import { Button, message, Popconfirm, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useFloorPlanDelete } from "./hooks/useFloorPlanDelete";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { useFloorPlan, useFloorPlanEditMode } from "./FloorPlanContext";
import { FloorPlanHeaderTitle } from "./FloorPlanHeaderTitle";
import { FloorPlanHeaderPinBar } from "./FloorPlanHeaderPinBar";
import { useFloorPlanBackgroundUpdate } from "./hooks/useFloorPlanBackgroundUpdate";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { FloorPlanHeaderKPIs } from "./FloorPlanHeaderKPIs";
import { FloorPlanFloorSelect } from "./FloorPlanFloorSelect";

export function FloorPlanHeader() {
  const t = useTranslations();
  const navigate = useNavigate();
  const assets = useFloorPlanAssets();
  const floorPlan = useFloorPlan();
  const building = useCurrentBuilding();
  const floorPlanDelete = useFloorPlanDelete();
  const [isEditing, setIsEditing] = useFloorPlanEditMode();
  const floorPlanUpdate = useFloorPlanUpdate();
  const floorPlanBackgroundUpdate = useFloorPlanBackgroundUpdate();

  async function handleBeforeUpload(file: RcFile) {
    const maxSizeMegaBytes = 8;
    const maxSizeBytes = maxSizeMegaBytes * 1024 * 1024;

    if (!file.type.startsWith("image/")) {
      message.error(t("floor-plan-v2.messages.file-not-supported"));
      return false;
    }

    if (file.size > maxSizeBytes) {
      message.error(t("floor-plan-v2.messages.file-too-large"));
      return false;
    }

    return true;
  }

  return (
    <div>
      <PageHeader
        title={<FloorPlanHeaderTitle />}
        extra={
          <div className="flex gap-2">
            {isEditing && (
              <Fragment>
                <FloorPlanFloorSelect
                  showSearch
                  loading={assets.isLoading}
                  disabled={floorPlanUpdate.isMutating}
                  defaultValue={floorPlan.floor}
                  className="w-96"
                  onChange={(value) =>
                    floorPlanUpdate.trigger({
                      floor: value,
                    })
                  }
                />
                <Upload
                  showUploadList={false}
                  multiple={false}
                  beforeUpload={handleBeforeUpload}
                  accept="image/*"
                  maxCount={1}
                  customRequest={(opts) =>
                    floorPlanBackgroundUpdate.trigger({
                      file: opts.file as RcFile,
                    })
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={floorPlanBackgroundUpdate.isMutating}
                  >
                    {t("floor-plan-v2.update-image")}
                  </Button>
                </Upload>
                <Popconfirm
                  placement="top"
                  title={t("floor-plan-v2.delete-pop-title")}
                  okText={t("floor-plan-v2.delete-pop-ok-text")}
                  cancelText={t("floor-plan-v2.delete-pop-cancel-text")}
                  onConfirm={() =>
                    floorPlanDelete.trigger(undefined, {
                      onSuccess() {
                        navigate(`/asset/${building.id}/floor-plan`);
                      },
                    })
                  }
                >
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    loading={floorPlanDelete.isMutating}
                    danger
                  >
                    {t("floor-plan-v2.delete")}
                  </Button>
                </Popconfirm>
              </Fragment>
            )}
            <Button type="default" onClick={() => setIsEditing(!isEditing)}>
              {isEditing
                ? t("floor-plan-v2.edit-exit")
                : t("floor-plan-v2.edit-enter")}
            </Button>
            <Fragment>
              <ToggleSidebarButton
                hiddenWhenSidebarVisible
                className="ml-2"
                sidebarHiddenContent={t("notes.show-notes-button")}
                rootClassName="!ml-0"
              />
              <NotesSidebar
                noteSource={NoteSource.WEB_FLOOR_PLAN}
                assetFilterMode={NotesAssetFilterMode.TimeseriesList}
                idSet={
                  new Set(
                    floorPlan.pins.map((pin) => {
                      return pin.timeseriesId;
                    }),
                  )
                }
                buildings={[
                  {
                    id: building.dataSetId as number,
                    name: building.name,
                  },
                ]}
              />
            </Fragment>
          </div>
        }
      />
      {isEditing ? (
        <div className="pb-4 pl-4">
          <FloorPlanHeaderPinBar />
        </div>
      ) : (
        <FloorPlanHeaderKPIs />
      )}
    </div>
  );
}
