import { QueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { IncidentFilter, IncidentsLoader } from "@/features/alarms";
import { listIncidents } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import type { OrderBy } from "@properate/common";

export const getUrl = (
  id: string,
  search?: string,
  sort?: { property: string; order: string },
  filters?: IncidentFilter[],
) => {
  let url = `/asset/${id}/incidents`;
  if (search) {
    url += `?search=${search}`;
  }
  if (sort) {
    const divider = search ? "&" : "?";
    url += `${divider}sort=${sort.property}&order=${sort.order}`;
  }
  if (filters) {
    const divider = search || sort ? "&" : "?";
    url += `${divider}filters=${filters.join("&filters=")}`;
  }
  return url;
};

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

const getFilters = (filters: IncidentFilter[]) =>
  filters.reduce((acc, filter) => {
    if (filter === "all") {
      return {};
    }
    acc[filter] = true;
    return acc;
  }, {} as any);

const fetchIncidents = async (
  pageData: IncidentsLoader & {
    buildingExternalId: string;
  },
  pageParam?: string,
) => {
  {
    const order_by = pageData.sort
      ? (`${pageData.sort
          ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
      : undefined;

    const filters = getFilters(pageData.filters);

    try {
      const incidents = await listIncidents({
        search: pageData.search || "",
        limit: PAGE_LIMIT,
        filter: {
          building_external_id: pageData.buildingExternalId,
          ...filters,
        },
        order_by,
        cursor: pageParam,
      });

      return {
        items: incidents.items,
        nextCursor: incidents.next_cursor,
      };
    } catch (error) {
      console.error("error", error);
    }

    return {
      items: [],
      nextCursor: undefined,
    };
  }
};

const getQuery = (
  pageData: IncidentsLoader & {
    buildingExternalId: string;
  },
) => ({
  queryKey: [
    "incidents",
    pageData.buildingExternalId,
    pageData.search,
    pageData.sort,
    pageData.filters,
  ],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchIncidents(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useIncidentsData = () => {
  const pageData = useLoaderData() as IncidentsLoader;
  const currentBuilding = useCurrentBuilding();

  const query = getQuery({
    ...pageData,
    buildingExternalId: currentBuilding.externalId!,
  });

  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    query,
    queryClient,
  );

  const dataTable = data?.pages.flatMap((page) => page.items) || [];

  return {
    incidents: dataTable,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};
