import { useTranslations } from "@properate/translations";
import { CenteredSpinner } from "@properate/ui";
import { Box, BoxVariant } from "../../components/InfoBox";
import { useAlarmTimeseriesForCurrentSubBuilding } from "../../hooks/useAlarmTimeseriesForCurrentSubBuilding";
import { useFormValue } from "../../FormContext/hooks";
import { TimeseriesThresholdFormFields } from "../TimeseriesThreshold";
import { TimeseriesWithAlarmAssetName } from "../../../utils/getAlarmTimeseries";
import { OffsetDifference, ResponseDelaySelect } from "./SelectOffset";
import { SelectSubBuilding } from "./SelectSubBuilding";

export enum FixedTimeseriesName {
  RoomTempHigh = "RT001-max",
  RoomTempLow = "RT001-min",
  VOC = "RY002-max",
  CO2 = "RY001-max",
  LeakProtection = "RH002-norm",
}

export function FixedTimeseriesThresholdDelayOnly({
  hideOffset = false,
}: Readonly<{
  fixedTimeseries: FixedTimeseriesName;
  hideOffset?: boolean;
}>) {
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [subBuildingId] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const {
    timeseriesForCurrentSubBuilding,
    isLoading,
    error,
    currentSubBuildingAvailable,
  } = useAlarmTimeseriesForCurrentSubBuilding();

  const t = useTranslations();

  function findTimeseries(
    key: keyof TimeseriesWithAlarmAssetName,
    value: unknown,
  ) {
    return (timeseriesForCurrentSubBuilding ?? []).find(
      (ts) => ts[key] === value,
    );
  }

  const timeseries = findTimeseries("id", timeseriesId);

  function renderTimeseriesBasedFields() {
    if (subBuildingId && !currentSubBuildingAvailable) {
      return (
        <Box variant={BoxVariant.Info}>
          <p>{t("alarm-details.common.error-messages.type-unavailable")}</p>
        </Box>
      );
    }
    if (!timeseries) {
      return null;
    }
    return (
      <>
        {hideOffset ? null : <OffsetDifference unit={timeseries.unit ?? ""} />}
        <ResponseDelaySelect />
      </>
    );
  }

  if (error) {
    return <Box variant={BoxVariant.Error}>{error.message}</Box>;
  }
  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <SelectSubBuilding />
      {renderTimeseriesBasedFields()}
    </>
  );
}
