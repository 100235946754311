import { IncidentClientSide } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { TableProps } from "antd/es/table/InternalTable";
import {
  IncidentFilter,
  OrgIncidentsLoader,
  useIncidentColumns,
} from "@/features/alarms";
import FullLayout from "@/layout/FullLayout";
import usePageTitle from "@/hooks/usePageTitle";
import { IncidentTableFilters } from "@/pages/alarmSystem/incidents/IncidentTableFilters";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { Sort } from "@/pages/fileType/types";
import { useWindowSize } from "@/hooks/useWindowSize";
import { getUrl, useIncidentsData } from "./utils";
import type { SorterResult } from "antd/es/table/interface";

function OrgIncidentsPage() {
  const t = useTranslations();
  usePageTitle(t("org-incidents.title"));
  const pageData = useLoaderData() as OrgIncidentsLoader;
  const navigate = useNavigate();
  const { incidents, onLoadMore, isLoading } = useIncidentsData();

  const doSearch = (search: string) => {
    navigate(getUrl(search, pageData.sort));
  };

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(pageData.search, {
        property: sort.property,
        order: sort.order,
      }),
    );
  };

  const doFilter = (filters: IncidentFilter[]) => {
    navigate(getUrl(pageData.search, pageData.sort, filters));
  };

  const onClick = (id: string) => {
    navigate(`${id}`);
  };

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<IncidentClientSide>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  const columns = useIncidentColumns();

  const { height: windowHeight } = useWindowSize();

  return (
    <FullLayout
      pageName={t("org-incidents.title")}
      headerRight={
        <>
          <IncidentTableFilters
            key="filters"
            onChangeFilters={doFilter}
            activeFilters={pageData.filters}
          />
          <Input.Search
            defaultValue={pageData.search}
            data-testid="search-for-incidents"
            key="search"
            placeholder={t("org-incidents.search")}
            allowClear
            style={{ width: 300 }}
            onChange={(event) => doSearch(event.target.value)}
            aria-label={t("org-incidents.search")}
          />
        </>
      }
      hideSearchInput
      showNotesButton
    >
      <TableInfiniteScroll<IncidentClientSide>
        columns={columns}
        dataSource={incidents}
        height={windowHeight - 64}
        onReachedEndOfPage={onLoadMore}
        rowKey="id"
        loading={isLoading}
        onRow={(record) => ({
          onClick: () => onClick(record.id!),
        })}
        onChange={onChangeTable as TableProps<IncidentClientSide>["onChange"]}
      />
    </FullLayout>
  );
}

export default OrgIncidentsPage;
