import { Select, SelectProps } from "antd";
import { sortString } from "@properate/ui";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";

export function useFloorPlanFloorOptions() {
  const assets = useFloorPlanAssets();

  const sortedFloors = assets.data
    ? assets.data.floors.sort((a, b) =>
        sortString(a.description, b.description),
      )
    : [];

  return sortedFloors.reduce(
    (floors, floor) => {
      if (floor.description && floor.externalId) {
        floors[floor.description] = floors[floor.description]
          ? `${floors[floor.description]},${floor.externalId}`
          : floor.externalId;
      }

      return floors;
    },
    {} as Record<string, string>,
  );
}

export function FloorPlanFloorSelect(props: SelectProps<string>) {
  const floors = useFloorPlanFloorOptions();

  return (
    <Select {...props}>
      {props.children}
      {Object.entries(floors).map(([floor, externalIds]) => (
        <Select.Option key={floor} value={externalIds}>
          {floor}
        </Select.Option>
      ))}
    </Select>
  );
}
