import {
  AlarmRuleTypeName,
  OffsetDirection,
  PartialAlarmRule,
} from "@properate/common";
import { useEffect, useState } from "react";
import { CenteredSpinner } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { useAlarmTimeseriesForCurrentSubBuilding } from "@/pages/alarms/details/hooks/useAlarmTimeseriesForCurrentSubBuilding";
import { Box, BoxVariant } from "@/pages/alarms/details/components/InfoBox";
import { numberValidator } from "../FormContext/validators";
import { useFormValue } from "../FormContext/hooks";
import { TimeseriesWithAlarmAssetName } from "../../utils/getAlarmTimeseries";
import { getSubmitValueEntry } from "../FormContext/utils";
import { requiredSelect, tKey } from "./common/utils";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  getTypeSpecificOffsetAlarmRuleFields,
  OffsetDifference,
  OffsetDirectionSelect,
  OffsetMode,
  ResponseDelaySelect,
  SelectOffsetType,
} from "./common/SelectOffset";
import {
  TimeseriesThresholdAlarmMetaFields,
  TimeseriesThresholdFormFields,
  TimeseriesThresholdTypeSpecificSummary,
} from "./TimeseriesThreshold";
import { SelectSubBuilding } from "./common/SelectSubBuilding";
import { FixedTimeseriesName } from "./common/FixedTimeseriesThresholdDelayOnly";
import { AlarmRuleType } from "./index";

export const TimeseriesThresholdRoomTempDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.TimeseriesThresholdRoomTemp,
  labelTranslationKey: tKey("timeseries-threshold.room-temp.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
      },
      [TimeseriesThresholdFormFields.SubBuilding]: {
        defaultValue: alarmRule?.sub_building_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.sub-building`),
              ).toLowerCase(),
            }),
          ),
      },
      ...getSelectOffsetFieldDefinitions({
        alarmRule,
        mode: OffsetMode.Threshold,
      }),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      sub_building_id: getSubmitValueEntry<number>(
        entries,
        TimeseriesThresholdFormFields.SubBuilding,
      ),
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          ...getTypeSpecificOffsetAlarmRuleFields({ entries }),
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
        },
      },
    };
  },
  formComponent: <TimeseriesThresholdRoomTemp />,
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  deviationsTable: (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
        DeviationTableColumn.Notes,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
    />
  ),
  availability: {
    subBuildingMustHaveNamedTimeseries: [
      FixedTimeseriesName.RoomTempHigh,
      FixedTimeseriesName.RoomTempLow,
    ],
  },
};

function SelectMaxMin() {
  const [subBuilding] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  if (!subBuilding) {
    return null;
  }
  return (
    <OffsetDirectionSelect
      labelKey={tKey(`timeseries-threshold.select-max-min.label`)}
      labels={{
        [OffsetDirection.BaseGTCompare]: tKey(
          `timeseries-threshold.select-max-min.max`,
        ),
        [OffsetDirection.BaseLTCompare]: tKey(
          `timeseries-threshold.select-max-min.min`,
        ),
      }}
    />
  );
}

function TimeseriesThresholdRoomTemp() {
  const [subBuildingId] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const [timeseriesId, setTimeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [maxMin] = useFormValue<OffsetDirection>(SelectOffsetType.Direction);
  const t = useTranslations();
  const {
    isLoading,
    error,
    timeseriesForCurrentSubBuilding,
    currentSubBuildingAvailable,
  } = useAlarmTimeseriesForCurrentSubBuilding();

  const [timeseriesError, setTimeseriesError] = useState<Error | null>(null);

  function findTimeseries(
    key: keyof TimeseriesWithAlarmAssetName,
    value: unknown,
  ) {
    return (timeseriesForCurrentSubBuilding ?? []).find(
      (ts) => ts[key] === value,
    );
  }

  const timeseries = findTimeseries("id", timeseriesId);

  useEffect(
    () => {
      if (
        subBuildingId === undefined ||
        subBuildingId === null ||
        isLoading ||
        !maxMin
      ) {
        return;
      }
      let tsName = "";
      if (maxMin === OffsetDirection.BaseGTCompare) {
        tsName = FixedTimeseriesName.RoomTempHigh;
      } else if (maxMin === OffsetDirection.BaseLTCompare) {
        tsName = FixedTimeseriesName.RoomTempLow;
      }
      if (!tsName) {
        setTimeseriesError(
          new Error(
            t("alarm-details.common.error-messages.timeseries-loading"),
          ),
        );
        return;
      }
      const ts = findTimeseries("name", tsName);
      if (!ts) {
        setTimeseriesError(
          new Error(
            t("alarm-details.common.error-messages.timeseries-loading"),
          ),
        );
        return;
      }
      setTimeseriesError(null);
      setTimeseriesId(ts.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subBuildingId, maxMin, isLoading],
  );

  function renderTimeseriesBasedFields() {
    if (!timeseries) {
      return null;
    }
    return (
      <>
        <OffsetDifference unit={timeseries.unit ?? ""} />
        <ResponseDelaySelect />
      </>
    );
  }

  function renderSubBuildingFields() {
    if (subBuildingId && !currentSubBuildingAvailable) {
      return (
        <Box variant={BoxVariant.Info}>
          <p>{t("alarm-details.common.error-messages.type-unavailable")}</p>
        </Box>
      );
    }

    return (
      <>
        <SelectMaxMin />
        {renderTimeseriesBasedFields()}
      </>
    );
  }

  if (error ?? timeseriesError) {
    return (
      <Box variant={BoxVariant.Error}>
        {(error ?? timeseriesError)!.message}
      </Box>
    );
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <SelectSubBuilding />
      {renderSubBuildingFields()}
    </>
  );
}
