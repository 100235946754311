import { MessageKey } from "@properate/translations";
import { ReactNode } from "react";
import { DeepPartial } from "ts-essentials";
import {
  AlarmRule,
  AlarmRuleTypeName,
  PartialAlarmRule,
} from "@properate/common";
import { FixedTimeseriesName } from "@/pages/alarms/details/AlarmRuleTypes/common/FixedTimeseriesThresholdDelayOnly";
import { getSubmitValueEntry } from "../FormContext/utils";
import { AlarmSettingsFormFields } from "../AlarmSettingsPage/types";
import {
  CreateAlarmRuleCommonProps,
  FormContextProviderEntries,
} from "../FormContext/types";
import { ThirdPartyAlarmDefinition } from "./ThirdPartyAlarm";
import { LockedThirdPartyAlarmDefinition } from "./LockedThirdPartyAlarm";
import { CompareTimeseriesDefinition } from "./CompareTimeseries";
import { TimeseriesThresholdDefinition } from "./TimeseriesThreshold";
import { TimeseriesThresholdRoomTempDefinition } from "./TimeseriesThresholdRoomTemp";
import { TimeseriesThresholdCO2HighDefinition } from "./TimeseriesThresholdCO2High";
import { TimeseriesThresholdVOCHighDefinition } from "./TimeseriesThresholdVOCHigh";
import { LeakProtectionDefinition } from "./LeakProtection";
import { MissingDataDefinition } from "./MissingData";
import { CompareTimeseriesPredictedEnergyConsumptionDefinition } from "./CompareTimeseriesPredictedEnergyConsumption";
import { TimeseriesThresholdEffectGuardDefinition } from "./TimeseriesThresholdEffectGuard";

export type AlarmRuleType = {
  name: AlarmRuleTypeName;
  labelTranslationKey: MessageKey;
  getFormFields: (alarmRule: PartialAlarmRule) => FormContextProviderEntries;
  getAlarmRuleFields: ({
    entries,
  }: CreateAlarmRuleCommonProps) => PartialAlarmRule;
  formComponent: ReactNode;
  lockedSchedule?: boolean;
  lockedType?: boolean;
  getNotificationFieldOverrides?: (
    alarmRule: PartialAlarmRule,
  ) => PartialAlarmRule;
  summaryContents?: {
    generalAlarmMetaFields?: ReactNode;
    typeSpecificSummary?: ReactNode;
  };
  deviationsTable: ReactNode;
  availability?: {
    // Contains values that restrict availability of the alarm type.
    // If object does not exist or is empty, the alarm type is always available.
    subBuildingMustHaveNamedTimeseries?: FixedTimeseriesName[];
  };
};

export const alarmRuleTypes: Record<AlarmRuleTypeName, AlarmRuleType> = {
  [AlarmRuleTypeName.CompareTimeseries]: CompareTimeseriesDefinition,
  [AlarmRuleTypeName.TimeseriesThreshold]: TimeseriesThresholdDefinition,
  [AlarmRuleTypeName.TimeseriesThresholdRoomTemp]:
    TimeseriesThresholdRoomTempDefinition,
  [AlarmRuleTypeName.TimeseriesThresholdCO2]:
    TimeseriesThresholdCO2HighDefinition,
  [AlarmRuleTypeName.TimeseriesThresholdVOC]:
    TimeseriesThresholdVOCHighDefinition,
  [AlarmRuleTypeName.LeakProtection]: LeakProtectionDefinition,
  [AlarmRuleTypeName.MissingData]: MissingDataDefinition,
  [AlarmRuleTypeName.CompareTimeseriesPredictedEnergyConsumption]:
    CompareTimeseriesPredictedEnergyConsumptionDefinition,
  [AlarmRuleTypeName.TimeseriesThresholdEffectGuard]:
    TimeseriesThresholdEffectGuardDefinition,
  [AlarmRuleTypeName.LockedThirdPartyAlarm]: LockedThirdPartyAlarmDefinition,
  [AlarmRuleTypeName.ThirdPartyAlarm]: ThirdPartyAlarmDefinition,
};
export const alarmRuleTypeList: AlarmRuleType[] = Object.values(alarmRuleTypes);

export function getFormFieldsForAlarmRuleType(
  alarmRule: DeepPartial<AlarmRule>,
  alarmRuleType?: AlarmRuleTypeName,
): FormContextProviderEntries {
  if (!alarmRuleType || !(alarmRuleType in alarmRuleTypes)) {
    return {};
  }
  return alarmRuleTypes?.[alarmRuleType]?.getFormFields(alarmRule) ?? {};
}

export function getAlarmRuleFieldsForAlarmRuleType({
  entries,
  alarmRuleType,
}: CreateAlarmRuleCommonProps & {
  alarmRuleType?: AlarmRuleTypeName;
}): PartialAlarmRule {
  if (!alarmRuleType || !(alarmRuleType in alarmRuleTypes)) {
    return {};
  }
  return alarmRuleTypes?.[alarmRuleType]?.getAlarmRuleFields({ entries }) ?? {};
}

export function addSubTypeNotificationFieldOverrides({
  entries,
  partialAlarmRule,
}: CreateAlarmRuleCommonProps & {
  partialAlarmRule: PartialAlarmRule;
}): PartialAlarmRule {
  const alarmRuleType = getSubmitValueEntry<AlarmRuleTypeName>(
    entries,
    AlarmSettingsFormFields.Type,
  );

  if (!alarmRuleType) {
    return partialAlarmRule;
  }

  return (
    alarmRuleTypes?.[alarmRuleType]?.getNotificationFieldOverrides?.(
      partialAlarmRule,
    ) ?? partialAlarmRule
  );
}
