import { cn } from "@properate/ui";
import { useEffect, useRef } from "react";
import { Pin } from "./types";
import { FloorPlanMapPinSetPointStrategy } from "./FloorPlanMapPinSetPointStrategy";
import { FloorPlanMapPinSensorStrategy } from "./FloorPlanMapPinSensorStrategy";
import { FloorPlanPinProvider } from "./FloorPlanMapPinContext";
import { FloorPlanMapPinValue } from "./FloorPlanMapPinValue";
import { FloorPlanMapPinPopover } from "./FloorPlanMapPinPopover";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { FloorPlanMapPinEditableSign } from "./FloorPlanMapPinEditableSign";
import {
  MoveHandler,
  useFloorPlanMapMoveHandlers,
} from "./FloorPlanMapContext";
import { FloorPlanMapPinDeleteButton } from "./FloorPlanMapPinDeleteButton";

type Props = {
  pin: Pin;
};

export function FloorPlanMapPin(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEditing] = useFloorPlanEditMode();
  const moveHandlers = useFloorPlanMapMoveHandlers();

  const FloorPlanMapPinOnClickStrategy =
    props.pin.type === "set-point-general"
      ? FloorPlanMapPinSetPointStrategy
      : FloorPlanMapPinSensorStrategy;

  useEffect(() => {
    const handler: MoveHandler = ({ zoom }) => {
      if (containerRef.current) {
        const scale = Math.max(
          0.2,

          // Scale down the elements for every 1 step in zoom, 0 = 1, 1 = .8, 2 = .6, 3 = .4, 4 = .2, 5 = .2
          Math.min(1, (5 - Math.round(zoom)) * 0.2),
        );

        containerRef.current.style.scale = scale.toFixed(1);
      }
    };

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers]);

  return (
    <div
      ref={containerRef}
      className={cn(
        "flex gap-4 bg-background text-background-foreground rounded-full hover:bg-card",
        "border border-solid border-border/50 shadow-md",
        isEditing && "cursor-grab active:cursor-grabbing",
        props.pin.type === "set-point-general" &&
          "border-orange-400 shadow-orange-300/50 text-orange-600 dark:text-orange-300",
      )}
    >
      <FloorPlanPinProvider pin={props.pin}>
        <FloorPlanMapPinPopover>
          <FloorPlanMapPinOnClickStrategy>
            <FloorPlanMapPinEditableSign>
              <FloorPlanMapPinDeleteButton>
                <FloorPlanMapPinValue />
              </FloorPlanMapPinDeleteButton>
            </FloorPlanMapPinEditableSign>
          </FloorPlanMapPinOnClickStrategy>
        </FloorPlanMapPinPopover>
      </FloorPlanPinProvider>
    </div>
  );
}
